<template>
    <section class="obras-servicios-forma-pago container-fluid custom-scroll overflow-auto">
        <div class="row mb-5">
            <div class="col-10">
                <indicador-progress-value 
                :actual-value="resumen.total_pagado" 
                :total-value="resumen.total_valor_pagar" 
                :last-date="resumen.fecha_pago"
                />
            </div>
            <div class="col-2">
                <el-tooltip placement="bottom-end" effect="light" visible-arrow popper-class="like-buttons">
                    <template #content>
                        <p class="p-2 cr-pointer item" @click="toggleReportPayment">Reportar pago</p>
                        <p v-if="!finished" class="p-2 cr-pointer item" @click="toggleFinishPayment">Finalizar pago</p>
                    </template>
                    <button class="btn btn-general px-3 f-12">Actualizar pago</button>
                </el-tooltip>
            </div>
        </div>
        <div class="row justify-content-center mb-3">
            <div class="col-10">
                <FormaPagoTable ref="formaPagoTable" v-model="valid"/>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-10">
                <div class="row justify-content-end mx-5">
                    <div class="col-auto">
                        <button class="btn btn-general px-3 f-12" @click="beforeSavePaymentMethod">Guardar</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- partials -->
        <modal-reportar-pago ref="modalReportPayment" @report="beforeReportPayment"/>
        <modal-finalizar-pago ref="modalFinishPayment" @finish="beforeFinishPayment"/>
    </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import FormaPagoTable from './components/formaPagoTable'
import modalReportarPago from '~/pages/compras/partials/modalReportarPago'
import modalFinalizarPago from '~/pages/compras/partials/modalFinalizarPago'
export default {
    components: { FormaPagoTable, modalReportarPago, modalFinalizarPago},
    data(){
        return{
            valid: false,
            datePicker: '',
            inputPorcentaje: '',
            inputValorCuota: '',
        }
        
    },
    computed: {
        ...mapGetters({
            resumen: 'obras/servicios/forma_pago_coutas/payment_methods_resume',
            payment_methods: 'obras/servicios/forma_pago/payment_methods',
        }),
        id_proyecto(){
            return this.$route.params.id_proyecto
        },
        id_control_piso(){
            return this.$route.params.id_control_piso
        },
        finished(){
            return this.resumen.total_pagado == this.resumen.total_valor_pagar
        }
    },
    async created(){
        this.$router.push({ query: { cuota: this.radio }})
        await Promise.all([
            this.Action_get_payment_methods_resume({
                id_proyecto: this.id_proyecto,
                id_control_piso: this.id_control_piso,
            }),
            this.Action_get_payment_methods({
                id_proyecto: this.id_proyecto,
                id_control_piso: this.id_control_piso,
            })
        ])
        this.getSelects(['Action_get_select_monedas'])
    },
    methods:{
        ...mapActions({
            Action_get_payment_methods_resume: 'obras/servicios/forma_pago_coutas/Action_get_payment_methods_resume',
            Action_get_payment_methods: 'obras/servicios/forma_pago_coutas/Action_get_payment_methods',
            Action_save_payment_methods: 'obras/servicios/forma_pago_coutas/Action_save_payment_methods',
            Action_report_payment: 'obras/servicios/forma_pago_coutas/Action_report_payment',
            Action_finish_payment: 'obras/servicios/forma_pago_coutas/Action_finish_payment',
            getSelects: 'selects/selects/sync'
        }),
        toggleReportPayment(){
            this.$refs.modalReportPayment.toggle()
        },
        toggleFinishPayment(){
            this.$refs.modalFinishPayment.toggle()
        },
        async beforeReportPayment(payload){
            await this.Action_report_payment({
                id_proyecto: this.id_proyecto,
                id_control_piso: this.id_control_piso,
                payload,
            })
            this.toggleReportPayment()
        },
        async beforeFinishPayment(){
            await this.Action_finish_payment({
                id_proyecto: this.id_proyecto,
                id_control_piso: this.id_control_piso,
            })
            this.toggleFinishPayment()
        },
        async beforeSavePaymentMethod(){
            if(!this.valid) return 
            await this.Action_save_payment_methods({
                id_proyecto: this.id_proyecto,
                id_control_piso: this.id_control_piso,
                payload: this.payment_methods,
            })
        }
    }

}
</script>

<style lang="scss" scoped>
.obras-servicios-forma-pago{
    height: calc(100vh - 385px);
}
</style>