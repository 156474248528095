<template>
    <modal ref="modalFinishPayment" titulo="Finalizar pago" adicional="Finalizar" @adicional="finalizarPago">
        <div class="row m-3 f-12">
            <p class="col-12 text-center">¿Está seguro que quiere finalizar este pago?</p>
        </div>
    </modal>
</template>

<script>
export default {
    methods:{
        toggle(){
            this.$refs.modalFinishPayment.toggle()
        },
        finalizarPago(){
            this.$emit('finish')
        }
    }
}
</script>